<template>
  <div>
    <main class="container">
      <link-component></link-component>

      <div class="row g-5">
        <div class="col-md-12">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                Destaques
              </li>
            </ol>
          </nav>

          <h4 class="pb-4 mb-4 fst-italic border-bottom text-center">
            Publicações de Destaques            
          </h4>

          <div class="row row-cols-1 row-cols-md-3 g-4 mb-5">
            <div
              class="col"
              v-for="notas in notaList"
              :key="notas.id"
            >
              <article class="blog-post p-4 mb-2 bg-light rounded">              
                <img
                  v-if="notas.image_path"
                  :src="urlImgNota + notas.id"
                  class="img-fluid rounded"
                  :alt="notas.titulo"
                  :title="notas.titulo"
                />
                <img
                  v-else
                  src="../../assets/images/semimagem.jpg"
                  class="img-fluid rounded"
                  :alt="notas.titulo"
                  :title="notas.titulo"
                />
                <h4 class="fst-italic mt-2">{{ notas.titulo }}</h4>
                <p class="blog-post-meta" v-if="moment">
                  <i class="bi bi-calendar3"></i>
                  {{ moment(notas.created_at).format("DD MMMM YYYY") }}
                </p>

                <span v-html="notas.subtitulo"></span>
                <nav class="blog-pagination mt-2" aria-label="Pagination">
                  <a
                    class="btn btn-outline-primary"
                    :href="'/ler/nota/' + notas.id"
                    >Saiba mais...</a
                  >
                </nav>
              </article>
            </div>
          </div>          
        </div>
      </div>

      <div class="mt-4 mb-4">
        <apoio-component></apoio-component>
      </div>
    </main>
  </div>
</template>

<script>
import { URL_BASE } from "../../services/Commons";
import NotasService from "../../services/notadiaria.service";
import apoioComponent from "../../components/apoioComponent.vue";
import linkComponent from "../../components/linkComponent.vue";
import moment from 'moment'
import { useRoute } from "vue-router";
import { event } from 'vue-gtag';


export default {
  name: "ListarNotasDiarias",
  data: () => ({
    notaList: [],
    urlBase: "",
    id: null,
    urlImgNota: "",
    moment:null,
  }),
  components: {
    apoioComponent,
    linkComponent,    
  },
  mounted() {
    event('entrou_notas', { method: 'Google' })
    this.moment = moment;
    this.moment.locale('pt-br');
    
    this.urlBase = URL_BASE;
    this.urlImgNota = URL_BASE + `notadiariadowimagem/`;

    const route = useRoute();
    this.id = route.params.id;    

    NotasService.getPaginacao(30).then((result) => {
      if (result.status < 400) {
        this.notaList = result.data;        
      }
    });

  },
  methods: {
  
  },
};
</script>

<style>
</style>
